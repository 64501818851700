<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center m-0">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix pl-lg-5">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">Oops! You're lost.</h4>
            <p class="text-muted">The page you are looking for was not found.</p>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import {
  CContainer,
  CRow,
  CCol
} from '@coreui/vue';

export default {
  name: 'Page404',
  components: {
    CContainer,
    CRow,
    CCol
  }
};
</script>
